import React, { useState } from "react";
import loadable from "@loadable/component";
const Parser = loadable(() => import("../parser.js"));
import "../../styles/blocks/definitions.scss";

const Definitions = (props) => {
  const [isOpen, setOpen] = useState(false);
  return <dl className={(isOpen ? "open":"closed") + " definitions wp-block-gco-locked-group-definitions"}>
    <Parser>{ props.content }</Parser>
    {
      props.seeMore ?
        <div>
          <button
            onClick={ () => { setOpen((value) => !value); } }>
            Voir { isOpen ? "moins" : "plus" } de détails
          </button>
        </div>
      : <></>
    }
  </dl>;
};

export default Definitions;
